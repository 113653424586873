import React from 'react';
import { Navbar } from '../components/Navbar';

export class NotFoundPage extends React.Component {

    state = {
        userID: -1,
        isLoggedIn: false,
        requiresLogin: false
    };
    
    isLoggedIn = () => {
        let loggedIn = localStorage.getItem("userID") && localStorage.getItem("userID") !== -1;
        return loggedIn;
    }

    render() {

        return (
            <>
                <Navbar history={this.props.history}></Navbar>
                <div class="bg-light">
                    <div>
                        <h1>404: Page not found</h1>
                    </div>
                </div>

            </>
        );
        
    }

}