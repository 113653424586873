import React from 'react';
// import axios from 'axios';
import { Navbar } from '../components/Navbar';

export class NewsPage extends React.Component {

    state = {
        userID: -1,
        isLoggedIn: false
    };

    render() {

        return (
            <>
                <Navbar></Navbar>
                <div>Clomp.io News!</div>
            </>
        );
        
    }

}