import axios from "axios";
import { API_BASE } from "../config.js";
export default class Auth {

    async register(newUser) {
        return new Promise((resolve, reject) => {
            newUser.deviceType = "Web";
            axios.post(`${API_BASE}/auth/register`, newUser).then((response) => {
                if (response.status === 200) {
                    resolve(response.data.result);
                } else {
                    alert(response.data.message);
                }
            });
        });
    }

    async login(username, password) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE}/auth/login`, {
                username: username,
                password: password,
                deviceType: "Web",
            })
            .then((response) => {
                console.log(response);
                if (response.status === 200) {
                    resolve(response.data);
                } else {
                    reject(response.data);
                }
            });
        });
    }

    async logout(userToken) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_BASE}/auth/logout`, { user: userToken, deviceType: "Web" })
            .then(() => {
                resolve();
            })
            .catch((error) => {
                alert(error);
                reject();
            });
        });
    }

    async getIDByToken(token) {
        return new Promise((resolve,reject) =>{
            axios.get(`${API_BASE}/auth/getID/${token}`)
                .then(x => {
                    console.log(x.data);
                    resolve(x.data);
                })
            .catch(x => {
                alert(x);
                reject(x);
            })
        })
    }
    
}
