import React from 'react';
import './App.css';
// import axios from 'axios';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import { GamesPage } from './pages/GamesPage.jsx'
import { HomePage } from './pages/HomePage.jsx'
import { Login } from './pages/Login.jsx'
import { ProfilePage } from './pages/ProfilePage.jsx'
import { NewsPage } from './pages/NewsPage.jsx'
import { SignupPage } from './pages/SignupPage.jsx'
import { WordleGame } from './components/Wordle';
import { ManiaPage } from './pages/ManiaPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { NonogramGame } from './components/Nonogram';
import { ClompyClickerGame } from './components/ClompyClicker';

// React functional component
function App () {

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" component={HomePage}/>
          <Route exact path="/games" component={GamesPage} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/home" component={HomePage} />
          <Route exact path="/profile/:id" component={ProfilePage} />
          <Route exact path="/news" component={NewsPage} />
          <Route exact path="/register" component={SignupPage} />
          <Route exact path="/wordle" component={WordleGame} />
          <Route exact path="/mania" component={ManiaPage} />
          <Route exact path="/nonogram" component={NonogramGame} />
          <Route exact path="/clompyclicker" component={ClompyClickerGame} />
          <Route exact path='*' component={NotFoundPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
