import React, { Component } from 'react';
import { Navbar } from './Navbar';
import seedrandom from 'seedrandom';

import './Nonogram.css';

class PuzzleBox extends Component {

    render() {
        let incorrect = this.props.status.indexOf('incorrect') !== -1;
        let className = (this.props.status.replace('incorrect', '') || 'empty');
        className = "puzzlebox puzzlebox-" + ((this.props.status === ' ' || !this.props.status) ? 'empty' : className);

        /* onclick call this.props.onClick */

        return (
            <div className={className} onClick={() => {
                this.props.onClick(0);
            }}
                onContextMenu={(e) => {
                    this.props.onClick(1);
                    e.preventDefault();
                }}>
                <p></p>
                {incorrect ? 'X' : ''}
            </div>
        )
    }

}

export class NonogramGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            puzzleSize: 8,
            gameOver: false,
            showHelp: false,
            showShare: false,
            won: false,
            lost: false,
            puzzle: [],
            labelsX: [],
            labelsY: [],
            solution: [],
            puzzleId: -1,
            numMistakes: 0,
            minSize: 3,
            maxSize: 16
        }

        let puzzle = [];
        for (let i = 0; i < this.state.puzzleSize; i++) {
            let row = [];
            for (let j = 0; j < this.state.puzzleSize; j++) {
                row[j] = 'empty';
            }
            puzzle.push(row);
        }
        this.setState({ puzzle: puzzle });

    }

    getRandomSeed() {
        return Math.floor(Math.random() * 100000);
    }

    serializePuzzle() {
        // Creates URL suffix to identify this specific puzzle.
        // Combines puzzleSize with puzzleId
        let puzzleId = this.getPuzzleId();
        let puzzleSize = this.state.puzzleSize;
        return puzzleSize + '-' + puzzleId;
    }

    deserializePuzzle(puzzleSuffix) {
        // Takes URL suffix to identify this specific puzzle.
        // Separates puzzleSize and puzzleId
        let puzzleSize = puzzleSuffix.split('-')[0];
        let puzzleId = parseInt(puzzleSuffix.split('-')[1]);
        return { puzzleSize, puzzleId };
    }

    componentDidMount() {

        let puzzleSuffix = window.location.hash.substring(1);
        if (puzzleSuffix) {
            let { puzzleSize, puzzleId } = this.deserializePuzzle(puzzleSuffix);
            this.newGame(puzzleId, puzzleSize);
        }
        else {
            // generate random puzzleId
            let puzzleId = this.getRandomSeed();
            this.newGame(puzzleId, this.state.puzzleSize);
        }
    }

    // fix this
    getPuzzleId() {
        return this.state.puzzleId;
    }

    getShareURL() {
        return window.location.origin + window.location.pathname + '#' + this.serializePuzzle();
    }

    gameOver() {
        return this.state.gameOver;
    }

    setPuzzleSize(size) {

        let puzzleSize = Math.min(size, this.state.maxSize);
        let puzzle = [];
        for (let i = 0; i < puzzleSize; i++) {
            let row = [];
            for (let j = 0; j < puzzleSize; j++) {
                row[j] = 'empty';
            }
            puzzle.push(row);
        }

        // Initialize labelsX and labelsY
        let labelsX = [];
        let labelsY = [];
        for (let i = 0; i < puzzleSize; i++) {
            labelsX.push([]);
            labelsY.push([]);
        }

        return {
            puzzleSize: size,
            puzzle: puzzle
        };
    }

    checkPuzzle() {
        let puzzle = this.state.puzzle;
        let solution = this.state.solution;
        let puzzleSize = this.state.puzzleSize;

        let numMistakes = this.state.numMistakes;

        for (let i = 0; i < puzzleSize; i++) {
            for (let j = 0; j < puzzleSize; j++) {
                if (puzzle[i][j] !== 'empty') {
                    if ((puzzle[i][j].indexOf('yes') >= 0) !== solution[i][j]) {
                        numMistakes++;
                        puzzle[i][j] = 'incorrect' + (solution[i][j] ? 'yes' : 'no');
                    }
                }
            }
        }

        this.setState({
            numMistakes: numMistakes,
        });

        if (!puzzle.some(row => row.includes('empty'))) {
            this.setState({
                gameOver: true,
            });
        }
    }

    newGame = (puzzleId, puzzleSize) => {
        // make new game

        // Seed RNG with puzzleId
        let rng = seedrandom(puzzleId);

        // Create random solution board
        let solution = [];
        for (let i = 0; i < puzzleSize; i++) {
            let row = [];
            for (let j = 0; j < puzzleSize; j++) {
                row[j] = rng() > 0.5 ? true : false;
            }
            solution.push(row);
        }

        // Generate labels for X and Y using solution board
        let labelsX = [];
        let labelsY = [];

        for (let i = 0; i < puzzleSize; i++) {
            labelsX.push([]);
            labelsY.push([]);
        }

        for (let i = 0; i < puzzleSize; i++) {
            let streakLength = 0;
            for (let j = 0; j < puzzleSize; j++) {
                if (solution[i][j]) {
                    streakLength++;
                } else {
                    if (streakLength > 0) {
                        labelsX[i].push(streakLength);
                        streakLength = 0;
                    }
                }
            }
            if (streakLength > 0) {
                labelsX[i].push(streakLength);
            }
        }

        for (let j = 0; j < puzzleSize; j++) {
            let streakLength = 0;
            for (let i = 0; i < puzzleSize; i++) {
                if (solution[i][j]) {
                    streakLength++;
                } else {
                    if (streakLength > 0) {
                        labelsY[j].push(streakLength);
                        streakLength = 0;
                    }
                }
            }
            if (streakLength > 0) {
                labelsY[j].push(streakLength);
            }
        }

        this.setState(Object.assign({
            solution: solution,
            labelsX: labelsY,
            labelsY: labelsX,
            puzzleId: puzzleId,
            won: false,
            lost: false,
            gameOver: false,
            initialized: true,
            numMistakes: 0,
        }, this.setPuzzleSize(puzzleSize)));


    }

    toggleHelp() {
        this.setState({
            showHelp: !this.state.showHelp
        });
    }

    toggleShare() {
        this.setState({
            showShare: !this.state.showShare
        });
    }

    onBoxClick(a, i, j) {
        // Set state to yes
        let puzzle = this.state.puzzle;
        if (puzzle[i][j] === 'empty') {
            puzzle[i][j] = a === 0 ? 'yes' : 'no';
            this.setState({ puzzle: puzzle }, () => {
                this.checkPuzzle();
            });
        }
    }

    render() {
        // Set URL to reflect current puzzle
        if (this.state.puzzleId !== -1) {
            // window.location.hash = this.serializePuzzle();
        }
        let board = [];
        // let puzzle = this.state.puzzle;
        if (this.state.puzzle.length !== 0) {
            let labelsX = this.state.labelsX;
            let labelsY = this.state.labelsY;

            let labelsXElements = [<p className="label label-x" key="blank"></p>];
            for (let i = 0; i < this.state.puzzleSize; i++) {
                if (i < labelsX.length) {
                    labelsXElements.push(<div className="label label-x" key={"labelx" + i}>{labelsX[i].map((a) => {
                        return (<> {a} <br></br></>)
                    })}</div>);
                }
            }
            board.push(<div key="labelsx" className="puzzle-row">{labelsXElements}</div>);
            for (let i = 0; i < this.state.puzzleSize; i++) {
                let rowElements = [<div className="label label-y" key={"labely" + i}>{labelsY[i].join(" ")}</div>];
                let row = this.state.puzzle[i];
                for (let j = 0; j < this.state.puzzleSize; j++) {
                    rowElements.push(<PuzzleBox key={j} status={row[j]} onClick={(a) => { this.onBoxClick(a, i, j); }} />);
                }
                board.push(<div className='puzzle-row' key={i}>{rowElements}</div>)
            }
        }

        let helpPopup = (
            <div className='wordle-popup' onClick={this.toggleHelp.bind(this)}>
                <div className='wordle-popup-content'>
                    <h2>How to play</h2>
                    <p>
                        The goal of the game is to solve the puzzle.
                        <br />
                        Line 2.
                        <br />
                        Line 3.
                        <br />
                        Line 4.
                        <br />
                        Line 5.
                    </p>
                </div>
            </div>
        );

        let sharePopup = (
            <div className='wordle-popup' onClick={this.toggleShare.bind(this)}>
                <div className='wordle-popup-content'>
                    <h2>Share this puzzle</h2>
                    <div className='share-buttons'>
                        <a className='wordle-button' href={`https://twitter.com/intent/tweet?text=I%20just%20played%20a%20nonogram%20puzzle%20on%20clomp.io!%20Check%20it%20out%20at%20${encodeURIComponent(this.getShareURL())}`} target='_blank' rel='noopener noreferrer'
                            style={
                                {
                                    backgroundColor: '#1DA1F2',
                                    color: 'white'
                                }
                            }>
                            {/* twitter icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#FFFFFF" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>

                            </svg>
                        </a>
                        <div className='wordle-button' onClick={
                            () => {
                                navigator.clipboard.writeText(this.getShareURL());
                            }
                        }>
                            Copy link &nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="var(--color-tone-1)" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
        let postGame = (
            <div className='wordle-post-game'>
                <div className='wordle-button' onClick={() => {
                    this.newGame(this.getRandomSeed(), this.state.puzzleSize);
                }}>
                    New Game
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                        <path d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M12 5V1L7 6l5 5V7c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6H4c0 4.4 3.6 8 8 8s8-3.6 8-8-3.6-8-8-8zm-1.1 1V16zm4.
                            1 0 1-.1.1-.1.2-.1.1-.2.2-.2.1-.3.1-.2 0-.3-.1l-.2-.2s-.1-.2-.1-.3v-1.5zM21 13h-2V9h-2v4h-2V9H8v6h10v-2z"></path>
                    </svg>
                </div>
                <div className='wordle-button' onClick={this.toggleShare.bind(this)}>Share</div>
            </div>
        );

        return (
            <>
                <Navbar />
                <div className="nonogram">
                    <div className="puzzle-header">
                        <h1>Nonogram</h1>
                        <div onClick={this.toggleHelp.bind(this)}>
                            <svg height="24" viewBox="0 0 24 24" width="24">
                                <path fill="var(--color-tone-1)" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                            </svg>
                        </div>
                    </div>

                    {/*Puzzle Size Selector*/}
                    {this.state.initialized &&
                        <div className="row">
                            <div className="col-md-6">
                                <p>Puzzle Size: {this.state.puzzleSize}x{this.state.puzzleSize}</p>
                                <input type="number" min={this.state.minSize} max={this.state.maxSize} step="1" value={this.state.puzzleSize} onChange={(e) => {
                                    // this.game.setPuzzleSize(e.target.value);
                                    if (e.target.value >= this.state.minSize && e.target.value <= this.state.maxSize) {
                                        this.newGame(this.getRandomSeed(), e.target.value);
                                    }
                                    console.log("Puzzle set to " + this.state.puzzleSize + "x" + this.state.puzzleSize);
                                }} />
                            </div>
                            <div className="col-md-6">
                                Number of Mistakes: {this.state.numMistakes}
                            </div>
                        </div>
                    }

                    {/*Puzzle Board*/}
                    <div className="puzzle-board" onContextMenu={(e) => { e.preventDefault(); }}>
                        {board}
                    </div>

                    {this.gameOver() ? postGame : null}
                    {this.state.showHelp ? helpPopup : null}
                    {this.state.showShare ? sharePopup : null}
                </div>
            </>
        )
    }

}