// Rhythm game implemented in Javascript Canvas using WebAudio APIs and React

import React, { Component } from 'react';
import EventEmitter from 'eventemitter3';


class Song {
    constructor(name, artist, pathToAudio, songBPM, hitObjects) {
        this.name = name;
        this.artist = artist;
        this.pathToAudio = pathToAudio;
        this.songBPM = songBPM;
        if (hitObjects) {
            this.hitObjects = hitObjects;
            console.log('got map');
        }
        else {
            // Randomly generate a beat map for the song using 16th, 8th, and quarter notes
            // Notes can overlap
            this.hitObjects = [];
            var beatLengths = [0.25, 0.5, 1];
            let buttonTypes = ["left", "right", "up", "down"];
            // Duration and startTime can be any multiple of a random beatLength
            for (let i = 0; i < 1000; i++) {
                var duration = beatLengths[Math.floor(Math.random() * beatLengths.length)] * Math.floor(Math.random() * 4);
                var startTime = beatLengths[Math.floor(Math.random() * beatLengths.length)] * Math.floor(Math.random() * 4 - 2) + i;
                // Button is "left", "right", "up", "down" randomly
                var button = buttonTypes[Math.floor(Math.random() * buttonTypes.length)];

                this.hitObjects.push({
                    duration,
                    startTime,
                    button
                });
            }

            // If there are any notes for which the same button is overlapping, shorten the duration of the first note
            for (let i = 0; i < this.hitObjects.length; i++) {
                for (let j = 0; j < this.hitObjects.length; j++) {
                    if (i !== j && this.hitObjects[i].button === this.hitObjects[j].button && this.hitObjects[i].startTime + this.hitObjects[i].duration > this.hitObjects[j].startTime) {
                        this.hitObjects[i].duration = this.hitObjects[j].startTime - this.hitObjects[i].startTime;
                    }
                }
            }

            // If any note ends at the exact same time as another note with the same button, shorten the duration of the first note by 0.25
            for (let i = 0; i < this.hitObjects.length; i++) {
                for (let j = 0; j < this.hitObjects.length; j++) {
                    if (i !== j && this.hitObjects[i].button === this.hitObjects[j].button && this.hitObjects[i].startTime + this.hitObjects[i].duration === this.hitObjects[j].startTime) {
                        this.hitObjects[i].duration -= 0.25;
                        if (this.hitObjects[i].duration < 0.25) {
                            this.hitObjects[i].duration = 0.25;
                        }
                    }
                }
            }

        }
    }

    getName() {
        return this.name;
    }

    getArtist() {
        return this.artist;
    }

    getPathToAudio() {
        return this.pathToAudio;
    }

    getHitObjects() {
        return this.hitObjects;
    }

    getBPM() {
        return this.songBPM;
    }
}
class Game {
    constructor(canvas, song) {
        this.canvas = canvas;
        this.ctx = this.canvas.getContext('2d');
        this.width = this.canvas.width;
        this.height = this.canvas.height;
        this.ctx.fillStyle = '#FF0000';
        this.ctx.fillRect(0, 0, this.width, this.height);
        this.ctx.fillStyle = '#000000';
        this.ctx.font = '30px Arial';
        this.ctx.fillText('Game', this.width / 2 - 30, this.height / 2)

        // Song maps are reprsented as a list of hitobjects. Each hitobject can be a note or a hold note, and there are 4 possible buttons (left, right, up, down).
        // Each hitobject has a start time, a duration, and the associated button
        // The song maps are temporarily hardcoded in the constructor. 

        // All time values are relative to BPM, so 1 time unit corresponds to 1 beat in the song

        this.song = song;

        // Load song audio 
        this.audio = new Audio(this.song.getPathToAudio());

        this.visibleHitObjects = [];
        this.score = 0;
        this.combo = 0;
        this.noteSpeed = 0.8; // how fast notes fall
        this.noteSpawnOffsetBeats = 4; // how many beats notes spawn before they should be hit
        this.scoreThreshold = 0.1; // how many beats before a hitobject is considered missed\
        this.hitLine = 7 * this.height / 8; // where notes are judged to be hit

        this.leftPressed = false;
        this.rightPressed = false;
        this.upPressed = false;
        this.downPressed = false;

        this.lastCurrentTime = 0;

        // Create eventemitter
        this.events = new EventEmitter();
    }

    on(event, callback) {
        this.events.on(event, callback);
    }


    handleKeyDown(e) {
        if (e.key === 'Escape') {
            this.pause();
        }
        if (e.key === 'ArrowLeft') {
            this.leftPressed = true;
        } else if (e.key === 'ArrowRight') {
            this.rightPressed = true;
        } else if (e.key === 'ArrowUp') {
            this.upPressed = true;
        } else if (e.key === 'ArrowDown') {
            this.downPressed = true;
        }

        // Also dfjk should correspond to left, down, up, right
        if (e.key === 'd') {
            this.leftPressed = true;
        }
        if (e.key === 'f') {
            this.downPressed = true;
        }
        if (e.key === 'j') {
            this.upPressed = true;
        }
        if (e.key === 'k') {
            this.rightPressed = true;
        }
        console.log(e.key);
    }

    handleKeyUp(e) {
        if (e.key === 'ArrowLeft') {
            this.leftPressed = false;
        } else if (e.key === 'ArrowRight') {
            this.rightPressed = false;
        } else if (e.key === 'ArrowUp') {
            this.upPressed = false;
        } else if (e.key === 'ArrowDown') {
            this.downPressed = false;
        }

        if (e.key === 'd') {
            this.leftPressed = false;
        }
        if (e.key === 'f') {
            this.downPressed = false;
        }
        if (e.key === 'j') {
            this.upPressed = false;
        }
        if (e.key === 'k') {
            this.rightPressed = false;
        }
        console.log(e.key);

    }

    handleClick(e) {
        console.log(e.clientX, e.clientY);
    }

    begin() {
        this.audio.play();
        // Register event listeners
        document.addEventListener('click', this.handleClick.bind(this));
        document.addEventListener('keydown', this.handleKeyDown.bind(this));
        document.addEventListener('keyup', this.handleKeyUp.bind(this));
        this.audio.addEventListener('ended', this.end);

        // Begin game loop
        this.update();
    }

    pause() {
        return (!this.audio.paused) ? this.audio.pause() : this.audio.play();
    }

    end() {
        this.ctx.clearRect(0, 0, this.width, this.height);
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    spawnHitObject(hitobject) {
        let visibleObj = {
            hitobject,
            x: 0,
            y: 0,
            color: '#000000',
            wasHit: false
        };

        // If hitobject button is left, X position is at the left
        // If hitobject button is right, X position is at the right
        // If hitobject button is down, X position is in the middle left
        // If hitobject button is up, X position is in the middle right

        if (hitobject.button === 'left') {
            visibleObj.x = 0;
        } else if (hitobject.button === 'right') {
            visibleObj.x = this.width * 9 / 11;
        } else if (hitobject.button === 'down') {
            visibleObj.x = this.width * 3 / 11;
        } else if (hitobject.button === 'up') {
            visibleObj.x = this.width * 6 / 11;
        }
        return visibleObj;
    }

    setNoteSpeed(newSpeed) {
        this.noteSpeed = newSpeed;
    }

    setVolume(newVolume) {
        this.audio.volume = newVolume;
    }

    update() {
        // Get current song time from audio context
        let currentTimeSeconds = this.audio.currentTime;
        // Get current song time in beats
        let currentBeat = currentTimeSeconds * this.song.getBPM() / 60;

        let deltaTime = currentTimeSeconds - this.lastCurrentTime;
        let deltaBeat = deltaTime * this.song.getBPM() / 60;

        // Spawn new visible hitobjects if necessary
        // They should be spawned if the current beat is greater than the hitobject's start time + the noteSpawnOffsetBeats
        // Once spawned, the hitobject should be removed from the hitobject list
        // The hitobject list is assumed to be sorted by start time
        while (this.song.getHitObjects().length > 0 && this.song.getHitObjects()[0].startTime <= currentBeat + this.noteSpawnOffsetBeats) {
            let hitObject = this.song.getHitObjects()[0];
            this.visibleHitObjects.push(this.spawnHitObject(hitObject));
            this.song.getHitObjects().splice(0, 1);
        }


        // Animate falling hitobjects
        for (let i = 0; i < this.visibleHitObjects.length; i++) {
            let hitObject = this.visibleHitObjects[i];
            // noteSpeed of 1 means it takes 1 beat to fall the entire canvas height
            hitObject.y = this.height - ((hitObject.hitobject.startTime - currentBeat) * this.noteSpeed * this.height);
            // Offset by hitLine so that notes are not judged to be hit until they are at the hitLine
            hitObject.y -= this.height - this.hitLine;
            let height = hitObject.hitobject.duration * this.height * this.noteSpeed;
            if (hitObject.y - height > this.height) {
                this.visibleHitObjects.splice(i, 1);
                this.score -= 10;
            }
        }



        // Handle inputs
        // Go through all visible notes and check if they are hit
        for (let i = 0; i < this.visibleHitObjects.length; i++) {
            let hitObject = this.visibleHitObjects[i];
            // if (hitObject.y > this.height) {
            //     continue;
            // }
            let correctButtonPressed = false;
            if (this.leftPressed && hitObject.hitobject.button === 'left') {
                correctButtonPressed = true;
            } else if (this.rightPressed && hitObject.hitobject.button === 'right') {
                correctButtonPressed = true;
            } else if (this.upPressed && hitObject.hitobject.button === 'up') {
                correctButtonPressed = true;
            } else if (this.downPressed && hitObject.hitobject.button === 'down') {
                correctButtonPressed = true;
            }

            // Add score if correct button pressed and note is hit during its duration +- scoreThreshold (ms)
            if (currentBeat >= hitObject.hitobject.startTime && currentBeat <= hitObject.hitobject.startTime + hitObject.hitobject.duration) {
                if (correctButtonPressed) {
                    console.log(hitObject.hitobject.startTime, hitObject.hitobject.startTime + hitObject.hitobject.duration, currentBeat);
                    // console.log(hitObject.hitobject.startTime + hitObject.hitobject.duration - currentBeat);

                    this.score += 50 * deltaBeat;
                    hitObject.color = '#00FF00';
                    hitObject.wasHit = true;
                    this.combo++;
                } else if (hitObject.wasHit) {
                    hitObject.color = '#005500';
                }
            }
            // If note is missed or hit too late, set its color to red
            else if (currentBeat > hitObject.hitobject.startTime + hitObject.hitobject.duration + this.scoreThreshold && !hitObject.wasHit) {
                hitObject.color = '#FF0000';
                this.combo = 0;
            }
            // Otherwise set its color to black
            else {
                // hitObject.color = '#000000';
            }



            // // Remove hitobject if it is hit and its duration is over
            // if (correctButtonPressed && currentBeat - 1 > hitObject.hitobject.startTime + hitObject.hitobject.duration) {
            //     this.visibleHitObjects.splice(i, 1);
            // }
        }

        // Clear canvas
        this.ctx.clearRect(0, 0, this.width, this.height);

        // Render all hitobjects
        for (let i = 0; i < this.visibleHitObjects.length; i++) {
            let hitObject = this.visibleHitObjects[i];
            // Height of hitobject is proportional to its duration
            let height = hitObject.hitobject.duration * this.height * this.noteSpeed;
            this.ctx.fillStyle = hitObject.color;
            this.ctx.fillRect(hitObject.x, hitObject.y - height, this.width * 2 / 11, height);
        }

        // Render blue line at hitLine
        this.ctx.fillStyle = '#0000FF';
        this.ctx.fillRect(0, this.hitLine, this.width, 1);

        // Render keypress at hitline
        if (this.leftPressed) {
            this.ctx.fillStyle = '#0000FF';
            this.ctx.fillRect(0, this.hitLine, this.width * 2 / 11, 10);
        } if (this.rightPressed) {
            this.ctx.fillStyle = '#0000FF';
            this.ctx.fillRect(this.width * 9 / 11, this.hitLine, this.width * 2 / 11, 10);
        } if (this.upPressed) {
            this.ctx.fillStyle = '#0000FF';
            this.ctx.fillRect(this.width * 6 / 11, this.hitLine, this.width * 2 / 11, 10);
        } if (this.downPressed) {
            this.ctx.fillStyle = '#0000FF';
            this.ctx.fillRect(this.width * 3 / 11, this.hitLine, this.width * 2 / 11, 10);
        }

        // Render score text in top right right aligned
        // Render song name in top left
        this.ctx.fillStyle = '#000000';
        this.ctx.fillText(this.song.getName(), this.width / 8, this.height / 8);
        this.ctx.fillText(this.score, this.width * 7 / 8, this.height / 8);

        // Render percentage of song finished
        this.ctx.fillStyle = '#000000';
        let percentCompleted = (this.audio.currentTime / this.audio.duration) * 100;
        this.ctx.fillText(percentCompleted, this.width * 7 / 8, this.height / 4);

        // Render current beat
        this.ctx.fillStyle = '#000000';
        this.ctx.fillText(currentBeat, this.width / 8, this.height * 7 / 8);

        // Render combo
        this.ctx.fillStyle = '#000000';
        this.ctx.fillText(this.combo, 0, this.height * 7 / 8);

        this.events.emit('stats', {
            stats: this.getStats()
        });

        requestAnimationFrame(this.update.bind(this));
    }

    getStats() {
        return {
            score: this.score,
            combo: this.combo,
            song: this.song.getName(),
            currentBeat: this.audio.currentTime * this.song.getBPM() / 60,
            percentCompleted: (this.audio.currentTime / this.audio.duration) * 100,
            bpm: this.song.getBPM(),
            currentTime: this.audio.currentTime
        };
    }

};

export class RhythmGame extends Component {

    constructor(props) {
        super(props);
        this.state = {
            initialized: false,
            score: 0,
            combo: 0,
            song: '',
            currentBeat: 0,
            percentCompleted: 0,
            bpm: 0,
            currentTime: 0
        };

        this.startGame = this.startGame.bind(this)
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h1>Rhythm Game</h1>
                        {this.state.initialized &&
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Score: {this.state.score}</p>
                                    <p>Combo: {this.state.combo} </p>
                                    <p>Percent completed: {this.state.percentCompleted}</p>
                                    <p>Current beat: {this.state.currentBeat}</p>
                                    <p>Current time: {this.state.currentTime}</p>
                                    <p>BPM: {this.state.bpm}</p>
                                </div>
                                <div className="col-md-6">
                                    <p>Note speed: {this.state.noteSpeed}</p>
                                    <input type="range" min="0.1" max="1" step="0.01" value={this.state.noteSpeed} onChange={(e) => {
                                        this.game.setNoteSpeed(
                                            e.target.value
                                        );
                                    }} />

                                </div>
                                <div className="col-md-6">
                                    <p>Volume</p>
                                    <input type="range" min="0" max="1" step="0.01" value={this.state.volume} onChange={(e) => {
                                        this.game.setVolume(
                                            e.target.value
                                        );
                                    }} />
                                </div>
                            </div>
                        }
                        <div className="row">
                            <div className="col-md-12">
                                <button className="btn btn-primary" onClick={this.startGame}>Start Game</button>
                            </div>
                        </div>
                        <canvas id="canvas" width="1000" height="800"></canvas>
                    </div>
                </div >
            </div>
        );
    }


    componentDidMount() {

    }

    startGame() {

        // end existing game if one exists
        if (this.state.game !== null && this.state.initialized) {
            this.state.game.end();
        }
        // Create a new instance of the game with the canvas
        /* let objs = [
            // Each hitobject has a start time, a duration, and the associated button
            { startTime: 0, duration: 1.5, button: "left" },
            { startTime: 1, duration: 0.5, button: "right" },
            { startTime: 2, duration: 0.5, button: "up" },
            { startTime: 3, duration: 0.5, button: "down" },
            { startTime: 4, duration: 0.5, button: "left" },
            { startTime: 5, duration: 0.5, button: "right" },
            { startTime: 6, duration: 0.5, button: "up" },
            { startTime: 7, duration: 0.5, button: "down" },
            { startTime: 8, duration: 0.5, button: "left" },
        ];
        */

        // Load JSON from /beatmap.json
        let that = this;
        fetch('/beatmap.json')
            .then(response => response.json())
            .then(json => {
                let song = new Song("Test", "Test", "/audio.mp3", 185, json);
                let newgame = new Game(document.getElementById('canvas'), song);
                newgame.events.on('stats', ({ stats }) => {
                    that.setState(stats);
                });
                that.game = newgame;
                that.setState({ initialized: true });
                that.game.begin();
            });


        // let song = new Song("Test", "Test", "/audio.wav", 140);

        // // Add 4 beat offset to each hitobject
        // for (let i = 0; i < song.getHitObjects().length; i++) {
        //     song.getHitObjects()[i].startTime += 4;
        // }
        // let game = new Game(document.getElementById('canvas'), song);
        // game.begin();
    }
}

