import React from "react";
import { Navbar } from '../components/Navbar';
import { GamesCard } from "../components/GamesCard";

export class GamesPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userID: -1,
            requiresLogin: false
        };

    }

    render() {
        return (
            <>
                <Navbar />
                <div class="container">
                    <div class="album py-5">
                        <div class="games-card-container">
                            <div class="games-card-row">
                                <div class="games-card-element">
                                    <GamesCard name="Wordle" />
                                </div>
                                <div class="games-card-element">
                                    <GamesCard name="Nonogram" />
                                </div>
                            </div>
                            <div>
                                <hr></hr>
                            </div>
                            <div class="games-card-row">
                                <div class="games-card-element">
                                    <GamesCard name="Mania" />
                                </div>
                                <div class="games-card-element">
                                    <GamesCard name="Clompy Clicker" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}