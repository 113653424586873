import React from 'react';
import { Link } from 'react-router-dom';

import './GamesCard.css'

export class GamesCard extends React.Component {
    
    getDescription = (name) => {
        var descriptions = {
            wordle: "Wordle is a word guessing game.",
            nonogram: "Select the correct squares in each row and column to follow restrictions.",
            mania: "Hit keys to follow the music.",
            clompyclicker: "Click for points."
        };

        let desc = "";

        if (name === "wordle") {
            desc = descriptions.wordle;
        } else if (name === "nonogram") {
            desc = descriptions.nonogram;
        } else if (name === "mania") {
            desc = descriptions.mania;
        } else if (name === "clompy clicker") {
            desc = descriptions.clompyclicker;
        }

        return desc;
    }

    getLink = (name) => {
        var link = "/" + name;
        link = link.replace(/\s+/g, '');
        return link;
    }

    // This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.

    render() {
        return(
            <>
                <div className="box-shadow games-card">
                    <h3>{this.props.name}</h3>
                    <img className="card-img-top games-card-preview-image" src={"img/" + this.props.name.replace(' ', '') + "_CardImage.png"} alt={this.props.name.replace(' ', '')} />
                    <div className="card-body">
                        <p className="card-text">{this.getDescription(this.props.name.toLowerCase())}</p>
                        <div className="">
                            <div className="games-card-buttons">
                                <Link className="" to={this.getLink(this.props.name.toLowerCase())}>
                                    <button type="button" className="btn rounded btn-warning">Play</button>
                                </Link>
                                <Link className="" to={this.getLink(this.props.name.toLowerCase()) + "/scores"}>
                                    <button type="button" className="btn rounded btn-warning">Scores</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    )}


}