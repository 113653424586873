import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'
import { ClompApi } from '../api/ClompApi';

const api = new ClompApi();

export class Navbar extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            userID: -1
        };

    }

    async getUserID() {
        // return 1;
        api.auth.getIDByToken(localStorage.getItem('token')).then(response => {
            if(response.success === false) {
                return -1;
            }
            else {
                return response.userID;
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    async updateUserID() {
        await this.getUserID().then(async (userID) => {
            this.setState({ userID: userID });
        })
    }

    isLoggedIn = () => {
        if (localStorage.getItem('token') === null) {
            return false;
        }
        return true;
    }

    logout = () => {
        this.setState({ userID: -1 });
        localStorage.removeItem('token');
        console.log("Logged out");
        this.setState({ redirect: '/' });
    }

    render() {
        return (
            // Create Boostrap navbar with links to /, /games, /news, as well as placeholders for FAQs and About. On the right side, there is a login and register button. if the user is logged in, there is a logout button.
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                <Link className="navbar-brand" to="/">
                    {/* <img className="navbar-brand-logo" src="img/Clomp-logo.svg" alt="logo" /> */}
                    clomp
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item">
                            <Link className="nav-link" to="/games">Games</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/news">News</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/faq">FAQ</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/about">About</Link>
                        </li>
                    </ul>
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            {this.isLoggedIn() ?
                                <Link className="nav-link" to={"/profile/" + this.state.userID}>
                                    <button type="button" className="btn btn-primary">Profile</button>
                                </Link>
                                :
                                <Link className="nav-link" to="/register">
                                    <button type="button" className="btn btn-primary">Register</button>
                                </Link>
                            }
                        </li>
                        <li className="nav-item">
                            {this.isLoggedIn() ?
                                <Link className="nav-link" to="/">
                                    <button type="button" className="btn btn-primary" onClick={this.logout}>Logout</button>
                                </Link>
                                :
                                <Link className="nav-link" to="/login">
                                    <button type="button" className="btn btn-primary">Login</button>
                                </Link>
                            }
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }





                // <header class="p-3 bg-dark text-white">
                //     <div class="container">
                //         <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
                //             <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none">
                //             <img className="navbar-brand" src="img/Clomp-logo.svg" alt="Clomp.io"></img>
                //             </a>

                //             <ul class="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                //                 <li><a href="/" class="nav-link px-2 text-secondary">Home</a></li>
                //                 <li><a href="/games" class="nav-link px-2 text-white">Games</a></li>
                //                 <li><a href="/news" class="nav-link px-2 text-white">News</a></li>
                //                 <li><a href="/" class="nav-link px-2 text-white">FAQs</a></li>
                //                 <li><a href="/" class="nav-link px-2 text-white">About</a></li>
                //             </ul>

                //             <form class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
                //                 <input type="search" class="form-control form-control-dark" placeholder="Search..." aria-label="Search" />
                //             </form> 

                //             <div class="text-end">
                //                 <Link to="/login">
                //                     <button type="button" class="btn btn-outline-light me-2">Login</button>
                //                 </Link>
                //                 <Link to="/register">
                //                     <button type="button" class="btn btn-warning">Sign-up</button>
                //                 </Link>
                //             </div>
                //         </div>
                //     </div>
                // </header>
        

}