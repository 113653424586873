import React, { Component } from 'react';
import { Navbar } from './Navbar';
import './ClompyClicker.css';

export class ClompyClickerGame extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHelp: false,
            showShare: false,
            score: 0,
        }
    }

    getShareURL() {
        return window.location.origin + window.location.pathname;
    }

    toggleHelp() {
        this.setState({
            showHelp: !this.state.showHelp
        });
    }

    toggleShare() {
        this.setState({
            showShare: !this.state.showShare
        });
    }

    clompyClicked() {
        let score = this.state.score;
        score += 1;
        this.setState({
            score: score
        });
        var img = document.getElementById("clompy-img");

        img.classList.add('clompy-img-clicked');
        img.classList.remove('clompy-img-unclicked');

    }

    clompyReleased() {
        var img = document.getElementById("clompy-img");

        img.classList.add('clompy-img-unclicked');
        img.classList.remove('clompy-img-clicked');
    }
    
    // come up with name/what (cookie replacement) is being collected and clicked in clompyclicker
    render() {
        let helpPopup = (
            <div className='wordle-popup' onClick={this.toggleHelp.bind(this)}>
                <div className='wordle-popup-content'>
                    <h2>How to play</h2>
                    <p>
                        The goal of the game is to collect Clomp Coins.
                        <br />
                        Clomp Coins can be collected by clicking the mug.
                        <br />
                        Clomp Coins can also be spent to upgrade certain attributes.
                        <br />
                        Compete against others to gather the most Clomp Coins.
                    </p>
                </div>
            </div>
        );

        let sharePopup = (
            <div className='wordle-popup' onClick={this.toggleShare.bind(this)}>
                <div className='wordle-popup-content'>
                    <h2>Share this game</h2>
                    <div className='button-row'>
                        <a className='wordle-button' href={`https://twitter.com/intent/tweet?text=I%20just%20played%20a%20word%20puzzle%20with%20Wordle%20on%20clomp.io!%20Check%20it%20out%20at%20${encodeURIComponent(this.getShareURL())}`} target='_blank' rel='noopener noreferrer'
                            style={
                                {
                                    backgroundColor: '#1DA1F2',
                                    color: 'white'
                                }
                            }>
                            {/* twitter icon */}
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="#FFFFFF" d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path>

                            </svg>
                        </a>
                        <div className='wordle-button' onClick={ () => {
                                navigator.clipboard.writeText(this.getShareURL());
                            }}>
                            Copy link &nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path fill="var(--color-tone-1)" d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        );
        return (
            <>
                <Navbar />
                <div className="wordle">
                    <div className="wordle-header">
                        <h1>Clompy Clicker</h1>
                        <div onClick={this.toggleHelp.bind(this)}>
                            <svg height="24" viewBox="0 0 24 24" width="24">
                                <path fill="var(--color-tone-1)" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path>
                            </svg>
                        </div>
                    </div>

                    <div className="clompyclicker">
                        <div className="game-area">
                            <div>ClompCoins: {this.state.score}</div>
                            <div className="clicker-area">
                                <div className="img-wrapper">
                                    <img id="clompy-img" className="clompy-img-unclicked" src={"img/clompyclicker/mug.png"} alt="clompy" onMouseDown={this.clompyClicked.bind(this)} onMouseUp={this.clompyReleased.bind(this)}/>
                                </div>
                            </div>
                            <div className="button-row">
                                <button className="clompy-button">Upgrades</button>
                                <button className="clompy-button">Stats</button>
                                <button className="clompy-button">Leaderboard</button>
                            </div>
                        </div>
                    </div>

                    {this.state.showHelp ? helpPopup : null}
                    {this.state.showShare ? sharePopup : null}
                </div>
            </>
        )
    }

}