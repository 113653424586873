import React from 'react';
// import axios from 'axios';
import { Navbar } from '../components/Navbar';
import { RhythmGame } from '../components/Minigame';

export class ManiaPage extends React.Component {

    state = {
        userID: -1,
        isLoggedIn: false
    };

    render() {

        return (
            <>
                <Navbar></Navbar>
                <div></div>
                <RhythmGame></RhythmGame>

            </>
        );
        
    }

}