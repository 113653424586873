import React from 'react';
import { Navbar } from '../components/Navbar';
import { sha256 } from 'js-sha256'
import { Redirect } from 'react-router-dom';
import { ClompApi } from '../api/ClompApi';

const api = new ClompApi();

export class SignupPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            username: "",
            password: "",
            confirmPassword: "",
            status: "",
        };

        this.getEmail = this.getEmail.bind(this);
        this.getUsername = this.getUsername.bind(this);
        this.getPassword = this.getPassword.bind(this);
        this.getConfirmPassword = this.getConfirmPassword.bind(this);
        this.onRegister = this.onRegister.bind(this);
    }

    getEmail(emailAddress){
        this.setState({ email : emailAddress.target.value })
    }

    getUsername(name){
        this.setState({ username : name.target.value })
        localStorage.setItem('username', this.state.username);
    }

    getPassword(pass1){
        this.setState({ password : pass1.target.value })
    }

    getConfirmPassword(pass2){
        this.setState({ confirmPassword : pass2.target.value })
    }

    onRegister(){
        const { password, confirmPassword } = this.state;

        if(password !== confirmPassword){
            alert("Passwords do not match");

            this.setState({ status : false });
            document.getElementById("passwordV1").value = "";
            document.getElementById("passwordV2").value = "";
        }
        else{
            this.setState({ status : true })

            let password = this.state.password;
            password = sha256(password);
            var loginData = { email : this.state.email, password: password, username : this.state.username }

            const user = api.auth.register(loginData);

            console.log(user);

        }

    }

    render() {
        return(
        <>
            <Navbar></Navbar>
            <div className="p-3 container-fluid container-sm">
                <div className = "card text-center mb-1 w-75 mx-auto">
                    <div className = "jumbotron-fluid h-75 bg-dark text-white text-center">
                        <h4 className="p-4 card-title font-weight-bold">Register</h4>
                    </div>
                    <div className = "form-group">
                        <div className = "card-body">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col text-left">
                                        <label htmlFor="exampleFormControlInput1">Email</label>
                                        <div className = "email" onChange = {this.getEmail}>
                                            <input name="email" type="email" autoComplete="on" className="form-control mx-auto" placeholder="email@clomp.io"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col text-left">
                                        <label htmlFor="exampleFormControlInput1">Username</label>
                                        <div className = "username" onChange = {this.getUsername}>
                                            <input name="username" type="text" className="form-control mx-auto" placeholder="JDoe"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col text-left">
                                        <label htmlFor="exampleFormControlInput1">Password</label>
                                        <div className = "password" onChange = {this.getPassword}>
                                            <input id="passwordV1" autoComplete="current-password" name="password" type="password" className="form-control mx-auto" placeholder="SecretCode"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col text-left">
                                        <label htmlFor="exampleFormControlInput1">Confirm Password</label>
                                        <div className = "password" onChange = {this.getConfirmPassword}>
                                            <input id="passwordV2" autoComplete="current-password" name="confirmPassword" type="password" className="form-control mx-auto" placeholder="SecretCode"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="footer">
                            <button className="btn btn-dark rounded" onClick={() => this.props.history.push("/login")}>Return</button>
                            {(() => {
                                if(this.state.email && this.state.username && this.state.password && this.state.confirmPassword){
                                    return <>
                                        <button type="button" className="btn btn-primary rounded" onClick={this.onRegister}>Register</button>
                                        { this.state.status ? <Redirect to={"/home"}/> : <Redirect to={"/register"} /> }
                                        </>
                                }
                                else
                                    return <button type="button" className="btn btn-primary rounded button" disabled>Register</button>    
                            })()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )}
}