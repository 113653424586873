import React from 'react';
import { Navbar } from '../components/Navbar';
import './HomePage.css';

export class HomePage extends React.Component {

    state = {
        userID: -1,
        isLoggedIn: false,
        requiresLogin: false
    };
    
    isLoggedIn = () => {
        if (localStorage.getItem('token') === null) {
            return false;
        }
        return true;
    }

    render() {

        return (
            <>
                <Navbar history={this.props.history}></Navbar>
                <div className="bg-light">
                    <div>
                        <h1>Welcome to Clomp.io!</h1>
                    </div>
                </div>

            </>
        );
        
    }

}