import React from 'react';
import './Login.css';
import { Navbar } from '../components/Navbar';
import { sha256 } from 'js-sha256';
import { Redirect, Link } from "react-router-dom";
import { ClompApi } from '../api/ClompApi';

const api = new ClompApi();

export class Login extends React.Component {

    constructor(props){
        // localStorage.setItem('username', null)
        // localStorage.setItem('userID', null)
        super(props);

        this.state = {
            username: "",
            password: "",
            status: "",
        };

        this.onLogin = this.onLogin.bind(this)
        this.getUsername = this.getUsername.bind(this)
        this.getPassword = this.getPassword.bind(this)
        this.validLogin = this.validLogin.bind(this)
        this.invalidLogin = this.invalidLogin.bind(this)

        if (localStorage.getItem('token') !== null) {
            alert("You are already logged in!");
        }

    }

    async onLogin() {
        let password = this.state.password
        password = sha256(this.state.password);
        
        console.log("Logging in...");
        await api.auth.login(this.state.username, password).then(response => {
            if(response.success === false) {
                this.invalidLogin(response)
            }
            else {
                this.validLogin(response.token)
            }
        }).catch((err) => {
            console.log(err);
        });
    }
    
    getUsername(user) {
        var val = user.target.value;
        this.setState({username : val});
    }

    getPassword(pass) {
        var val = pass.target.value;
        this.setState({password : val});
    }

    async getUserID(username) {
        return (await this.login.userDetailsBody({username}))[0];
    }
    
    async validLogin(token) {
        localStorage.setItem('token', token);
        console.log("Login success");
        this.setState({status: true})
    }

    invalidLogin(data) {
        alert("Error: " + data.message);
        this.setState({status: false})
        document.getElementById("loginInfo").reset();
    }

    newAccount(){
        this.props.history.push("/register")
    }

    render() {

        return (
            <>
                <Navbar></Navbar>
                <div className="p-3 container-fluid container-sm">
                    <div className = "card text-center mb-1 w-75 mx-auto">
                        <div className = "jumbotron-fluid h-75 bg-dark text-white text-center">
                            <h4 className="p-4 card-title font-weight-bold">Login</h4>
                        </div>
                        <div className = "form-group">
                            <div className = "card-body">
                                <form id="loginInfo">
                                    <div className="form-row">
                                        <div className="form-group col text-left">
                                            <label htmlFor="exampleFormControlInput1">Username</label>
                                            <div className = "username" onChange={this.getUsername}>
                                                <input autoComplete="on" type="text" className="form-control mx-auto" name="username" placeholder="Username"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col text-left">
                                            <label htmlFor="exampleFormControlInput1">Password</label>
                                            <div className = "password" onChange={this.getPassword}>
                                                <input autoComplete="current-password" type="password" className="form-control mx-auto" name="password" placeholder="Password"></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col text-left">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="footer">

                                {(() => {
                                    if(this.state.password){
                                        return <div>
                                            <button className="btn btn-warning rounded" onClick={this.onLogin}>Log In</button>
                                            { this.state.status ? <Redirect to={"/home"}/> : <Redirect to={"/login"} /> }
                                        </div>
                                    }
                                    else
                                        return <button className="btn btn-warning rounded button" type="button">Log In</button>
                                })()}
                                <p></p>
                                <p>OR</p>

                                <Link to="/register">
                                    <button className="btn btn-dark rounded">Create Account</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Link to="/">
                        <button type="button" className="btn btn-link text-dark">Continue As Guest</button>
                    </Link>
                </div>
            </>
        );
        
    }

}