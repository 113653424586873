import axios from 'axios'
import { API_BASE } from '../config'

export default class User {
    // GET /user/:userID - Returns all information of userID
    getUserByID(userID){
        return new Promise((resolve,reject) =>{
            axios.get(`${API_BASE}/user/${userID}`)
                .then(x => {
                    resolve(x.data);
                })
            .catch(x => {
                alert(x);
                reject(x);
            })
        })
    }

}
