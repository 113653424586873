import React from 'react';
import { Navbar } from '../components/Navbar';

export class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileID: props.match.params.id,
        }
        console.log(this.state.profileID);

    }

    render() {

        return (
            <>
                <Navbar history={this.props.history}></Navbar>
                <div className="bg-light">
                    <div>
                        <h1>{"Profile of User: " + this.state.profileID}</h1>
                    </div>
                </div>

            </>
        );
        
    }

}